import { useRef } from "react";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";
import { replaceHrefInAnswer } from "./SignupUrlReplacer.js";

export default function FAQ({ className, data, isNewDesign = false }) {
  const faqContainer = useRef("");
  const keydownHandler = (event) => {
    if (
      (event.key === " " || event.key === "Enter") &&
      event.target.tagName !== "A"
    ) {
      event.preventDefault();
      accordianHandler(event);
    }
  };

  const accordianHandler = (event) => {
    const item = event.target;
    const previouslyOpen = faqContainer.current.querySelector(
      '[aria-expanded="true"]'
    );
    const isExpanded = item.getAttribute("aria-expanded") === "true";
    if (isExpanded) {
      item.setAttribute("aria-expanded", "false");
    } else {
      if (previouslyOpen !== null) {
        previouslyOpen.setAttribute("aria-expanded", "false");
      }
      item.setAttribute("aria-expanded", "true");
    }
  };

  const Title = () => (
    <>
      {data.title && (
        <h2
          className={`text-xl lg:text-center md:text-center lg:mb-10 ${
            isNewDesign ? "md:mb-5" : "md:mb-1.5"
          }`}
        >
          {data.title}
        </h2>
      )}
    </>
  );
  const Answers = ({ data }) => {
    return (
      <div className="faq-point" aria-live="polite">
        {data.map((answer, count) => (
          <p
            key={count}
            className={count === data.length - 1 ? "" : "lg:mb-1.5 md:mb-1.5"}
            dangerouslySetInnerHTML={{ __html: replaceHrefInAnswer(answer) }}
          ></p>
        ))}
      </div>
    );
  };

  const OpenListAnswers = ({ data }) => (
    <div>
      {data.map((answer, count) => (
        <p
          key={count}
          dangerouslySetInnerHTML={{ __html: replaceHrefInAnswer(answer) }}
        />
      ))}
    </div>
  );

  const OpenStateFAQs = () =>
    data.list.map((item, count) => (
      <li
        className="lg:px-3 lg:pt-3 lg:pb-4 md:pt-2.5 md:pl-2.5 md:pr-2.5 md:pb-3 bg-white lg:mb-5 md:mb-2.5"
        key={count}
      >
        <h3 className="text-md lg:mb-4 md:mb-3 text-black">{item.ques}</h3>
        <OpenListAnswers data={item.ans} />
        <style jsx>{`
          @media (max-width: ${viewport.tablet.sm}) {
            h3 {
              font-size: ${font.size.md[2]};
              line-height: ${lineHeight[7]};
            }
          }
        `}</style>
      </li>
    ));

  const CloseStateFAQs = () =>
    data.list.map((item, count) => (
      <li
        className="faq bg-white lg:w-auto lg:mb-5 md:mb-2.5"
        key={count}
        onClick={(e) => accordianHandler(e)}
        onKeyDown={(e) => keydownHandler(e)}
        aria-expanded="false"
        tabIndex="0"
        id={`faq-${count}`}
        role="button"
      >
        <label
          className="faq-ques w-100 pos-rel md:w-75 lg:pl-3 lg:pr-0 lg:pt-3 md:pt-2.5 md:pl-2.5 md:pr-0 md:pb-3 text-md text-black events-none"
          dangerouslySetInnerHTML={{ __html: item.ques }}
        />
        <Answers data={item.ans} />
      </li>
    ));

  const List = () =>
    data.list.length <= 3 ? <OpenStateFAQs /> : <CloseStateFAQs />;

  return (
    <section className={`faq-section ${className}`}>
      <div className="container lg:maxw-lg">
        <Title />
        <ul className="integrations-faq" ref={faqContainer}>
          <List />
        </ul>

        {data.note && (
          <p
            className="text-xs text-light-grayish-blue text-center lg:maxw-mds mx-auto"
            dangerouslySetInnerHTML={{ __html: data.note }}
          />
        )}
      </div>
      <style global jsx>{`
        .faq-section {
          text-align: left;
        }
        .faq-point .bullet-dot {
          padding-left: 16px;
        }
        .faq-point .bullet-dot:before {
          content: "";
          display: inline-block;
          width: 7px;
          height: 7px;
          background: ${colors.primitive.black[500]};
          margin-right: 5px;
          border-radius: 50%;
          border: 0;
          line-height: normal;
          text-align: center;
          position: relative;
          top: -2px;
          left: -7px;
        }
        .faq-section input {
          position: absolute;
          opacity: 0;
          z-index: -1;
        }
        .faq-section .faq {
          margin: 0 auto;
          width: 545px;
          border-radius: 8px;
          box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
          overflow: hidden;
          margin-bottom: 22px;
        }
        .faq-section .faq:hover,
        .faq-section .faq:active {
          box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
            0 1px 4px 0 rgba(22, 45, 61, 0.1);
        }
        .faq-section .faq[aria-expanded="false"] .faq-ques::after {
          content: "";
          right: 20px !important;
        }
        .faq-section .faq[aria-expanded="true"] .faq-ques::after {
          content: "";
          right: 20px !important;
          top: 50% !important;
          transform: translateY(-50%) rotate(0) !important;
        }
        .faq-section .faq[aria-expanded="false"] .faq-ques ~ .faq-point {
          visibility: hidden !important;
        }
        .faq-section .faq[aria-expanded="true"] .faq-ques ~ .faq-point {
          padding-bottom: 24px !important;
          max-height: initial !important;
          visibility: visible !important;
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .faq-section .faq {
            width: 100%;
            margin-bottom: 22px;
          }
        }
        .faq-section .faq-ques {
          font-size: ${font.size.md[2]};
          line-height: ${lineHeight[7]};
          font-weight: ${font.weight.bold};
          color: ${colors.primitive.black[100]};
          display: flex;
          justify-content: space-between;
          padding: 26px 60px 28px 20px !important;
          cursor: pointer;
          word-break: break-word;
        }
        .faq-section .faq-ques::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 20px;
          width: 24px;
          height: 24px;
          border: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
          transform: translateY(-50%) rotate(180deg);
          text-align: center;
          transition: all 0.35s;
          color: ${colors.primitive.violet[100]};
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .faq-section.faq-ques {
            font-size: ${font.size.md[1]};
            font-weight: ${font.weight.bold};
            padding-bottom: 25px;
          }
        }
        .faq-point {
          font-size: ${font.size.md[1]};
          line-height: ${lineHeight[7]};
          font-weight: ${font.weight.normal};
          color: ${colors.derived.primary};
          max-height: 0;
          transition: all 0.35s ease-in-out;
          padding: 0px 30px 0px 20px;
        }
        .faq-point p {
          font-size: ${font.size.md[1]};
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .faq-point p {
            font-size: ${font.size.sm[4]};
          }
        }
        .faq-section :global(.faq) :global(a),
        .faq-section :global(a) {
          color: ${colors.derived.brand};
        }
        .faq-section :global(a):hover {
          color: ${colors.primitive.blue[600]};
        }
        @media (min-width: ${viewport.tablet.sm}) {
          .faq-section :global(.container) {
            margin-left: auto;
            margin-right: auto;
          }
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .faq-section.faq-ques {
            font-size: ${font.size.sm[4]};
            line-height: ${lineHeight[7]};
            padding: 20px 60px 24px 20px !important;
          }
        }
        ${isNewDesign
          ? `
          .faq-section .faq-point p{
            font-size: ${font.size.sm[4]};
            line-height: ${lineHeight[6]};
          }
          .faq-section .faq-ques{
            padding: 24px 88px 24px 24px !important;
          }
            @media (max-width: ${viewport.tablet.sm}) {
              .faq-section .faq-ques {
                padding: 20px 84px 20px 20px !important;
              }
            }
          .faq-section .faq-ques::after {
            height: 48px;
            width: 48px;
            background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m32 28-8-8-8 8' stroke='%2348A36C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
          `
          : ``}
      `}</style>
    </section>
  );
}
